<template>
  <div class="data-box">
    <div class="data-box-head">
      温馨提示：请确认您在活动的组织名单内
    </div>
    <div class="data-box-body">
      <swiper class="swiper" :options="swiperOption" ref="activeSwiper">
        <swiper-slide v-for="(item,index) in swiperList" :key="index">
          <div :class="[item.type ? 'swiper-par' : 'swiper-org','swiper-box']">
            <div class="swiper-box-image">
              <van-image
                  class="image" round
                  :src="require(item.type ? '../assets/image/par_head.png' : '../assets/image/org_head.png')"
              />
            </div>
            <div class="swiper-box-text">{{ item.type ? '我是参与人' : '我是组织者' }}</div>
            <div class="swiper-box-form">
              <div class="form-label">活动编号</div>
              <van-field
                  class="form-value"
                  v-model="item.serialNo"
                  placeholder="请输入活动编号"
              />
              <div class="form-label">手机号</div>
              <van-field
                  class="form-value"
                  v-model="item.phone"
                  type="tel"
                  maxlength="11"
                  placeholder="请输入手机号"
              />
              <div class="form-label">验证码</div>
              <van-field
                  class="form-value"
                  v-model="item.authCode"
                  maxlength="6"
                  placeholder="请输入验证码"
              >
                <template #button>
                  <span class="code-btn" @click="getCode()">{{getCodeStatus === 0 ? '获取验证码' : (getCodeStatus === 1 ? (number + 's后重新获取') : '重新获取')}}</span>
                </template>
              </van-field>
              <div></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="userLogin">登录</van-button>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import {
  userLogin,
  getCodeSms
} from "@/api/index";

export default {
  name: 'Login',
  data(){
    return{
      swiperOption: {
        effect: "coverflow", //翻转效果
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30,
          stretch:30,
          depth: 170,
          modifier: 1,
          slideShadows: false
        },
        on: {
          slideChangeTransitionEnd:()=>{
            let that = this;
            let swiper = that.$refs.activeSwiper.swiper;
            that.swiperIndex = swiper.activeIndex;
          }
        }
      },
      swiperIndex: 0,
      swiperList: [
        {serialNo: "",phone: "",authCode: "",type: 1},
        {serialNo: "",phone: "",authCode: "",type: 0}
      ],
      formData: null,
      number: 60,
      getCodeStatus: 0,//0：未获取；1：已获取；2：重新获取
      timer: null
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  mounted(){

  },
  methods:{
    //获取验证码
    getCode(){
      let that = this;
      if(that.getCodeStatus === 1) return;
      let phone = that.swiperList[that.swiperIndex].phone;
      if(phone === ""){
        that.$toast('请输入手机号');
        return;
      }
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if(!reg.test(phone)){
        that.$toast('手机号格式有误');
        return;
      }
      let params = { phone: phone, type: 1 };
      getCodeSms(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.$toast('短信已发送');
          that.getCodeStatus = 1;
          that.timer = setInterval(this.countDown,1000);
        })
      },err => {})
    },
    countDown(){
      let that = this;
      if(that.number > 0){
        that.number--;
      }else{
        that.number = 60;
        that.getCodeStatus = 2;
        clearInterval(that.timer);
      }
    },
    //用户登陆
    userLogin(){
      let that = this;
      let serialNo = that.swiperList[that.swiperIndex].serialNo;
      let phone = that.swiperList[that.swiperIndex].phone;
      let authCode = that.swiperList[that.swiperIndex].authCode;
      if(serialNo === ""){
        that.$toast('请输入活动编号');
        return;
      }
      if(phone === ""){
        that.$toast('请输入手机号');
        return;
      }
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if(!reg.test(phone)){
        that.$toast('手机号格式有误');
        return;
      }
      /*if(that.getCodeStatus === 0){
        that.$toast('请获取验证码');
        return;
      }*/
      if(authCode === ""){
        that.$toast('请输入验证码');
        return;
      }
      that.formData = that.swiperList[that.swiperIndex];
      userLogin(that.formData).then(res => {
        if(that.$store.getters.getSerialNo != serialNo) {
          that.$store.commit("setSelectLivePersonId", "")
        }
        that.$tool.resValidation(res, () => {
          that.$store.commit('setToken', res.data.tokenLogin.token);
          that.$store.commit('setUserInfo', res.data.tokenLogin.user);
          that.$store.commit('setAyPerson', res.data.ayPerson);
          that.$store.commit('setSerialNo',serialNo);
          that.$store.commit('setUserPhone', phone);
          that.$tool.getActObjInfo(res.data.ayPerson.id, () => {
            that.$toast.success("登录成功");
            if(that.formData.type === 0){
              that.$router.push('orgIndex');
            }else{
              that.$router.push('parIndex');
            }
          });
        })
      },err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .data-box{
    width: 100%;
    .data-box-head{
      width: 90%;
      background-color:rgba(52,118,255,0.1);
      color: #3476FF;
      font-size: 14px;
      padding: 12px 5%;
      text-align: center;
    }
    .data-box-body{
      width: 100%;
      margin: 20px auto;
      .swiper-box{
        margin-top: 50px;
        width: 100%;
        height: 430px;
        background-size: 100% 430px;
        .swiper-box-text{
          width: 80%;
          margin: 0px auto;
          padding-top: 46px;
          color: #FFFFFF;
          font-size: 24px;
          text-align: center;
        }
        .swiper-box-image{
          width: 100%;
          position: absolute;
          margin-top: -50px;
          text-align: center;
          .image{
            width: 80px;
            height: 80px;
          }
        }
        .swiper-box-form{
          width: 80%;
          margin: 0px auto;
          .form-label{
            padding: 24px 16px 0px 16px;
            font-size: 16px;
            color: #FFFFFF;
          }
          .form-value{
            background: transparent;
            ::v-deep .van-field__control{
              display: block;
              box-sizing: border-box;
              width: 100%;
              min-width: 0;
              margin: 0;
              padding: 0;
              color: #FFFFFF;
              line-height: inherit;
              text-align: left;
              background-color: transparent;
              border: 0;
              resize: none;
            }
            ::v-deep input::-webkit-input-placeholder {
              color: #F2F5FC;
            }
            .code-btn{
              color: #FFFFFF;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
      .swiper-par{
        background-image: url("../assets/image/par_body.png");
      }
      .swiper-org{
        background-image: url("../assets/image/org_body.png");
      }
    }
    .data-box-foot{
      width: 86%;
      margin: 0px auto;
      padding-bottom: 30px;
    }
  }
</style>
